.voo-lang-switcher {
  @extend %text-uppercase;
  position: relative;
}

.voo-lang-switcher-inner {
  overflow: hidden;
  height: 5rem;
  cursor: pointer;

  .is-open & {
    border: 1px solid $c-blue-medium;

    .ie & {
      overflow: visible;
    }
  }
}

.voo-lang-switcher__item {
  @extend %fw-bold;
  height: 5rem;
  padding: 0 $spacing * 2 0 $spacing * .75;
  background-color: $c-blue-dark;
  color: rgba($c-light, .5);
  font-family: $ff-alt;
  font-size: 1.1rem;
  text-decoration: none;
  letter-spacing: 1.2px;
  transition-property: background-color, color;
  transition: .2s $ease-out-quart;

  &.is-active {
    order: -1;
    color: rgba($c-light, 1);
    pointer-events: none;

    a {
      color: rgba($c-light, 1);
    }
  }

  &:hover {
    background-color: lighten($c-blue-dark, 10%);
    color: rgba($c-light, 1);
  }

  a {
    color: rgba($c-light, .5);
    text-decoration: none;
  }
}

.voo-lang-switcher__icon {
  position: absolute;
  top: 1.9rem;
  right: 2rem;
  align-items: center;
  width: 1rem;
  height: 1rem;
  fill: $c-light;
  pointer-events: none;
  transform-origin: center center;
  transition: transform .2s ease-out;

  .is-open & {
    transform: rotate(180deg);
  }
}
