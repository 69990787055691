.voo-universe-switcher {
  position: relative;
}

.voo-universe-switcher-inner {
  display: flex;
  align-items: center;
  height: 5rem;
}

.voo-universe-switcher__item {
  &.is-active {
    // order: -1;
    pointer-events: none;
  }

  & + & {
    padding-left: $spacing;

    @include mq(l) {
      .voo-universe-switcher__item__link::after {
        @include center-y;
        content: '';
        left: -$spacing / 2;
        display: block;
        width: 2px;
        height: 80%;
        background-color: $c-light;
        opacity: 0.5;
      }
    }
  }
}

.voo-universe-switcher__item__link {
  @extend %text-uppercase;
  @extend %fw-bold;
  position: relative;
  color: $c-light;
  font-family: $ff-alt;
  font-size: 1.1rem;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity 0.3s $ease-out-quad;

  .is-active &,
  &:hover {
    opacity: 1;
  }

  @include mq('m') {
    letter-spacing: 1.2px;
  }

  &.universe-zuny {
    span {
      @extend %visually-hidden;

      text-align: unset;
      text-indent: -99999px;
    }
  }
}

.voo-universe-switcher__icon {
  @include center-y;
  right: $spacing;
  align-items: center;
  width: 1rem;
  height: 1rem;
  fill: $c-light;
  pointer-events: none;

  @include mq($until: 'l') {
    transform: rotate(180deg);
  }
}
