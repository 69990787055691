@charset 'UTF-8';

/*
 * Breakpoints, variables, functions, mixins, extends, helpers, …
 */
@import 'utils/utils';
@import 'utils/helpers';
@import 'utils/h-wrapper';

/*
 * Vendor
 */
@import 'vendor/sass-mq/mq';
@import 'vendor/normalize';

/*
 * Base
 */
// sass-lint:disable clean-import-paths
@import 'base/reset';
@import 'base/font';

body > svg {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

.voo-h1,
.h1,
.voo-h2,
.h2,
.voo-h3,
.h3,
.voo-h4,
.h4,
.voo-h5,
.h5 {
  @extend %fw-bold;
  font-family: $ff-alt;

  strong {
    @extend %fw-black;
  }
}

.voo-h1,
.h1 {
  margin-bottom: .75em;
  font-size: 3.6em / $body-text-s * 1em;
  line-height: 1.115;

  @include mq(m) {
    margin-bottom: .6em;
    font-size: 4.8em / $body-text-m * 1em;
  }

  @include mq(l) {
    margin-bottom: .45em;
    font-size: 7.2em / $body-text-l * 1em;
  }
}

.voo-h2,
.h2 {
  font-size: 2.8em / $body-text-s * 1em;
  line-height: 1.17;

  strong {
    font-weight: 900 !important;//sass-lint:disable-line no-important
  }

  @include mq(m) {
    font-size: 3.2em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 4.8em / $body-text-l * 1em;
  }
}

.voo-h3,
.h3 {
  font-size: 2.2em / $body-text-s * 1em;
  line-height: 1.2;

  @include mq(m) {
    font-size: 2.8em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 3.2em / $body-text-l * 1em;
  }
}

.voo-h4,
.h4 {
  font-size: 1.8em / $body-text-s * 1em;
  line-height: 1.25;

  @include mq(m) {
    font-size: 2.2em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 2.4em / $body-text-l * 1em;
  }
}

/*
 * Header
 */
@import '../scripts/components/chrome/header/Header.scss';
@import '../scripts/components/chrome/header-btn/HeaderBtn.scss';
@import '../scripts/components/chrome/universe-switcher/UniverseSwitcher.scss';
@import '../scripts/components/chrome/lang-switcher/LangSwitcher.scss';
@import '../scripts/components/chrome/cart/Cart.scss';
@import '../scripts/components/chrome/profile/Profile.scss';
@import '../scripts/components/chrome/logo/Logo.scss';
@import '../scripts/components/chrome/menu/Menu.scss';

/*
 * Footer
 */
@import '../scripts/components/chrome/footer/Footer.scss';
@import '../scripts/components/chrome/secondary-menu/SecondaryMenu.scss';
@import '../scripts/components/chrome/services/Services.scss';
@import '../scripts/components/chrome/pillars/Pillars.scss';
