.voo-header,
[class*='voo-header--'] {
  z-index: 200;
  top: 0;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @include mq($until: 'nav') {
    position: fixed;
    height: 100vh;
    background-color: $c-light;
    clip: rect(0, 100vw, 50px, 0);
  }

  @include mq('nav') {
    position: absolute;
  }
}

.voo-header__top-outer {
  background-color: $c-blue-dark;
}

.voo-header__top {
  z-index: 5;
  display: flex;
  color: $c-light;

  > * {
    height: 5rem;
    line-height: 5rem;
  }

  @include mq($until: 'xl') {
    padding-right: 0 !important; //sass-lint:disable-line no-important
    padding-left: $spacing * 1.5 !important; //sass-lint:disable-line no-important
  }

  @include mq('xxxl') {
    width: col(12);
    margin: 0 auto;
  }
}

.voo-header__top-inner {
  z-index: 1;
  // DEBUG: IE
  // display: flex;
  // justify-content: flex-end;
  width: 100%;
  height: 5rem;
  margin-left: auto;
  background-color: $c-blue-dark;
  color: $c-light;

  > * {
    height: 5rem;
    line-height: 5rem;
  }

  @include mq($until: 'nav') {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  @include mq('nav') {
    // DEBUG: IE
    display: flex;
    justify-content: flex-end;
  }
}

.voo-header__top__universe {
  z-index: 1;
  flex-shrink: 0;
  background-color: $c-blue-dark;

  @include mq($until: 'nav') {
    position: fixed !important; // sass-lint:disable-line no-important
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: $spacing;
    background-color: $c-blue-medium;
    transform: translateY(100%);
  }
}

.voo-header__top__business-number {
  margin: 0 $spacing * 0.75;

  a {
    @extend %fw-bold;
    color: $white;
    font-family: $ff-alt;
    font-size: 1.6rem;
    text-decoration: none;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    fill: $c-pink-medium;
    vertical-align: middle;
  }

  &:hover {
    a {
      color: $c-pink-medium;
    }
  }

  @include mq($until: 'nav') {
    position: absolute;
    top: 0;
    right: 12rem;
  }

  @include mq($until: 'm') {
    right: 6rem;
  }

  @include mq('l') {
    font-size: 1.8rem;
  }
}

.voo-header__top__lang {
  // margin-right: auto;

  // DEBUG: IE
  @include mq($until: 'nav') {
    float: left;
  }

  @include mq(m, l) {
    margin-left: $spacing * 3.5;
  }

  @include mq('nav') {
    margin-right: 0;
  }
}

.voo-header__top__cart {
  display: flex;
  flex-direction: column;
  padding: 0;

  // DEBUG: IE
  @include mq($until: 'nav') {
    position: absolute !important; // sass-lint:disable-line no-important
    top: 0;
    right: $spacing * 6;
  }

  @include mq($until: 'm') {
    right: $spacing * 2.5;
  }
}

.voo-header__top__profile {
  background-color: $c-pink-medium;
  transition: background-color 0.2s $ease-out-quad;

  &:hover {
    background-color: lighten($c-pink-medium, 10%);
  }

  &.is-connected {
    background-color: $c-blue-medium;
  }
}

.voo-header__nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: col(12);
  margin: 0 auto;

  @include mq($until: m) {
    padding-right: 0;
    padding-left: $spacing / 2;
  }

  @include mq('nav') {
    flex-wrap: initial;
    justify-content: initial;
    margin-top: $spacing * 1.5;

    &.s2n {
      margin-top: $spacing * 2.5;
    }
  }
}

.voo-header__nav__logo {
  left: $spacing;

  .icon {
    width: 7rem;
    height: 3rem;
  }

  &.is-business {
    .icon {
      width: 12rem;
    }
  }

  @include mq($until: 'nav') {
    margin-top: 1rem;
  }

  @include mq('m') {
    left: $spacing * 3.5;
  }

  @include mq('nav') {
    &.is-business {
      .icon {
        width: 16rem;
        margin-top: 1rem;
      }
    }
  }

  @include mq('xl') {
    position: absolute;
    top: 50%;
    left: $spacing * 6;
    transform: translateY(-50%);

    .icon {
      width: 10rem;
      height: 5rem;
    }
  }
}

.voo-header__nav__menu {
  margin: 0 0 0 auto;
  font-family: $ff-alt;

  @include mq($until: 'nav') {
    order: 3;
    width: 100%;
  }

  @include mq('nav') {
    margin: 0 auto;
  }
}

.voo-header__nav__btn {
  position: fixed;
  bottom: $spacing * 2.5;
  left: $spacing;
  order: 4;
  width: 100vw;
  margin-right: -$spacing;
  margin-left: -$spacing;

  @include mq('m') {
    left: $spacing * 3.5;
    margin-right: -$spacing * 3.5;
    margin-left: -$spacing * 3.5;
  }

  @include mq('nav') {
    display: none;
  }
}

// Trigger & Close
.voo-header__top__close {
  position: relative;
  width: 5rem;
  height: 5rem;
  cursor: pointer;

  // DEBUG: IE
  @include mq($until: 'nav') {
    float: right;
  }

  @include mq('m', 'nav') {
    margin-right: $spacing * 3.5;
  }

  @include mq('nav') {
    display: none;
  }
}

.voo-header__top__close__icon {
  @include center-xy;
  fill: $c-light;
  pointer-events: none;
}

.voo-header__nav__trigger {
  position: relative;
  width: 5rem;
  height: 5rem;
  margin-left: auto;
  cursor: pointer;

  @include mq('nav') {
    display: none;
  }
}

.voo-header__nav__trigger__icon {
  @include center-xy;
  fill: $c-blue-dark;
  pointer-events: none;
}
