// sass-lint:disable no-important
[class*='h-wrapper'],
[class*='h-wrapper-s'],
[class*='h-wrapper-m'],
[class*='h-wrapper-l'],
[class*='h-wrapper-xl'],
[class*='h-wrapper-xxl'] {
  margin-right: auto;
  margin-left: auto;
  padding-right: $wrapper-spacing-s;
  padding-left: $wrapper-spacing-s;
}

[class*='h-wrapper-s'] {
  max-width: $wrapper-width-s;
}

[class*='h-wrapper-m'] {
  max-width: $wrapper-width-m;
}

[class*='h-wrapper-l'] {
  max-width: $wrapper-width-l;
}

[class*='h-wrapper'],
[class*='h-wrapper-xl'] {
  max-width: $wrapper-width-xl;
}

[class*='h-wrapper'],
[class*='h-wrapper-m'],
[class*='h-wrapper-l'],
[class*='h-wrapper-xl'],
[class*='h-wrapper-xxl'] {
  @include mq('m') {
    padding-right: $wrapper-spacing-m;
    padding-left: $wrapper-spacing-m;
  }
}

[class*='h-wrapper'],
[class*='h-wrapper-xl'],
[class*='h-wrapper-xxl'] {
  @include mq('xl') {
    padding-right: $wrapper-spacing-xl;
    padding-left: $wrapper-spacing-xl;
  }
}

// No spacing
[class*='h-wrapper-'][class*='--ns-s'] {
  @include mq($until: 's') {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

[class*='h-wrapper-'][class*='--ns-m'] {
  @include mq($until: 'm') {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

[class*='h-wrapper-'][class*='--ns-l'] {
  @include mq($until: 'l') {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

[class*='h-wrapper-'][class*='--ns-xl'] {
  @include mq($until: 'xl') {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

[class*='h-wrapper-'][class*='--ns-xxl'] {
  @include mq($until: 'xxl') {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
