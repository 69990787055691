.pillars {
  position: relative;
  background-color: $c-blue-darker;
}

.pillars-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include mq($until: 'm') {
    justify-content: center;
  }
}

.pillars__title {
  @extend %text-center;
  width: col(12);
  margin: 0;
  color: $c-light;

  @include mq($until: 'm') {
    padding: $spacing * 1.5 0 $spacing;
  }

  @include mq('m') {
    width: col(3);
    margin-right: col(1);
    text-align: left;
  }

  @include mq('l') {
    width: col(2);
    margin-right: col(2);
  }

  @include mq('xl') {
    margin-right: col(1);
  }
}

.pillars__list {
  @extend %list-nostyle;
  display: flex;
  width: 100%;
  max-width: 42rem;

  @include mq($until: 'm') {
    justify-content: space-between;
  }

  @include mq('m') {
    margin-left: -$spacing;
  }

  @include mq('l') {
    max-width: 56rem;
    margin-left: -$spacing * 1.5;
  }
}

.pillars__item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing 0 $spacing * 1.5;
  text-decoration: none;
  transition: background-color .3s $ease-out-quart;

  @include mq('m') {
    flex-direction: row;
    padding: $spacing * 2 $spacing;

    &:hover {
      background-color: lighten($c-blue-dark, 10%);
    }
  }


  @include mq('l') {
    padding: $spacing * 2 $spacing * 1.5;
  }

}

.pillars__item__link__icon {
  height: 3rem;
  fill: $c-light;

  @include mq($until: 'm') {
    width: 5.5rem;
  }


  @include mq('m') {
    width: auto;
    margin-right: $spacing / 2;

    .ie & {
      width: 5;
    }
  }
}

.pillars__item__link__label {
  @extend %fw-ultra;
  @extend %text-uppercase;
  color: $c-light;
  font-size: 1.1rem;
  letter-spacing: 1.2px;
}
