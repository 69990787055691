.voo-footer {
  background-color: $c-blue-dark;
}

.voo-footer__nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $spacing;
  padding-bottom: $spacing;

  @include mq('m') {
    padding-top: $spacing * 2;
  }

  @include mq('xl') {
    flex-direction: row;
    padding-top: $spacing * 3;
  }
}

.voo-footer__partners-inner {
  display: flex;
  align-items: center;
  width: col(12);

  @include mq($until: 'xl') {
    justify-content: center;
  }
}

.voo-footer__partners__item {
  position: relative;
  width: 6rem;
  height: 3rem;
  margin-bottom: $spacing * 2;

  & + & {
    margin-left: $spacing;
  }
}

.voo-footer__partners__item__picture {
  @include image-fit(contain);
}

.voo-footer__legals-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: col(12);
  margin: 0 auto;
  border-top: 1px solid $gray-medium;
  padding-top: $spacing;
  padding-bottom: $spacing * 2;
  font-family: $ff-alt;
  font-size: 1.4rem;

  @include mq($until: 'xl') {
    align-items: center;
  }

  @include mq('xl') {
    flex-direction: row;
  }
}

.voo-footer__legals__copyright {
  color: $gray-medium;
  font-size: 1.4rem;

  @include mq($until: 'xl') {
    margin-bottom: 0;
  }
}

.voo-footer__legals__list {
  @extend %list-nostyle;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include mq('m') {
    justify-content: flex-end;
  }
}

.voo-footer__legals__item {
  @include mq($until: 'm') {
    margin: $spacing / 4;
    margin-bottom: $spacing / 4;
  }

  @include mq('m') {
    & + & {
      margin-left: $spacing;
    }
  }
}

.voo-footer__legals__item__link {
  color: $gray-medium;
  font-size: 1.1rem;
  text-decoration: none;
  transition: color 0.2s $ease-out-quart;

  &:hover {
    color: $c-light;
  }
}
