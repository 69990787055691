/**
 * Dev, based on $env (from gulp)
 */
$env: 'dev' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$black: rgb(0, 0, 0);
$gray-darkest: rgb(32, 32, 32);
$gray-darker: rgb(64, 64, 64);
$gray-dark: rgb(96, 96, 96);
$gray-medium: rgb(111, 113, 117); // Used
$gray-light: rgb(160, 160, 160);
$gray-lighter: rgb(192, 192, 192);
$gray-lightest: rgb(239, 239, 239);
$white: rgb(255, 255, 255);
$transparent: transparent;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;

// Semantic/project colors
$c-light: $white;
$c-cyan-contrast: #0cbaba;
$c-blue-contrast: #88b6ff;
$c-blue-lightest: #a5b9c4;
$c-blue-lighter: #808b9b;
$c-blue-light: #3c4d68;
$c-blue-medium: #222c3e;
$c-blue-dark: #121a28; // Midblue
$c-blue-darker: #0b111d;
$c-blue-light-pale: #d1dce2;
$c-blue-pale: #7c96a4;
$c-gray-darkest: #1b1b1b;
$c-gray-lightest: #e0e0e0;
$c-green-light: #7db200;
$c-gray-light: #b5b5b5;
$c-light-gray: #efefef;
$c-gray: #979797;
$c-gray-dark: hsl(220, 3%, 45%);
$c-gray-darker: #4d4d4d;
$c-pink-light: #ff80b5;
$c-pink-lightest: #f6c0da;
$c-pink-medium: #d4007a;
$c-purple: #8a1e65;
$c-anthracite-light: #274856;
$c-anthracite-lighter: #2d374e;
$c-orange: #ffa300;
$c-white-dark: #fafafa;
$c-error: #d64618;
$c-burgundy: #832652;
$c-light-blue: #007bb6;
$c-zuny-lighter: #00ffff;
$c-zuny-light: #00ffd7;
// Project colors

$c-proximus: #542791;
$c-orange-company: #ff6600;

// Primary
$c-gallery: #ebebeb;

// Secondary
$c-fiord: #3c4d68;
$c-regent-gray: #808b9b;
$c-tickle-me-pink: #ff80b5;

// Background colors
$bg-orange: 'is-orange';
$bg-white: 'is-white';
$bg-light-gray: 'is-light-gray';
$bg-lighter-gray: 'is-lighter-gray';
$bg-lightest-gray: 'is-lightest-gray';
$bg-light-blue: 'is-light-blue';
$bg-dark-blue: 'is-dark-blue';
$bg-darker-blue: 'is-darker-blue';
$bg-light-blue: 'is-light-blue';
$bg-colors: (
  $bg-orange: $c-orange,
  $bg-white: $white,
  $bg-light-gray: rgb(239, 239, 239),
  $bg-lighter-gray: #f7f7f7,
  $bg-lightest-gray: $c-white-dark,
  $bg-light-blue: $c-blue-light,
  $bg-dark-blue: #263043,
  $bg-darker-blue: $c-blue-dark,
);

// Video player
$plyr-color-main: $c-pink-medium;

// Z Layers
$z-layers: (
  lowest: 0,
  lower: 10,
  low: 20,
  high: 30,
  higher: 40,
  highest: 50,
  infinite: 999,
);

/**
 * Typography
 */

$base-font-size: 10px !default;

$ff-default: 'roboto', sans-serif;
$ff-alt: 'museo-sans-rounded', sans-serif;
$ff-zuny: 'value', sans-serif;
$body-text-s: 1.5em;
$body-text-m: 1.6em;
$body-text-l: 1.8em;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

// The `scalable-text-map` contains options for our `scalable-text` mixin.
// For each entry, we define a `min` map and a `max` map.
// In this example this means:
// - For a screen < 400px, the `h1` will be 24px.
// - For a screen > 800px, the `h1` will be 36px.
// - For any screen size in between, the `h1` will scale between 24px and 36px.
$scalable-text-map: (
  h1: (
    min: (
      24px,
      'small',
    ),
    max: (
      36px,
      'large',
    ),
  ),
  p: (
    min: (
      13px,
      'small',
    ),
    max: (
      18px,
      'large',
    ),
  ),
);

/*
 * Layout & spacing
 */

$spacing: 2rem;
$wrapper-width: get-breakpoint('xxl');
$columns-number: 12;
$header-height: 5rem;
$border-radius: 8px;

$spacings-map: (
  xxl: (
    l: 160px,
    m: 120px,
    s: 80px,
  ),
  xl: (
    l: 120px,
    m: 80px,
    s: 60px,
  ),
  l: (
    l: 80px,
    m: 60px,
    s: 40px,
  ),
  m: (
    l: 60px,
    m: 40px,
    s: 30px,
  ),
  s: (
    l: 40px,
    m: 30px,
    s: 20px,
  ),
  xs: (
    l: 20px,
    m: 15px,
    s: 10px,
  ),
  xxs: (
    l: 10px,
    m: 10px,
    s: 10px,
  ),
);

// $content-width: 144rem;

// Wrapper
$wrapper-spacing-s: $spacing;
$wrapper-spacing-m: $spacing * 3.5;
$wrapper-spacing-xl: $spacing * 6;
$wrapper-width-s: get-breakpoint('s');
$wrapper-width-m: get-breakpoint('m');
$wrapper-width-l: get-breakpoint('l'); // getting wrong values from those!
$wrapper-width-xl: $wrapper-width;

/**
 * RWD
 */
$mq-responsive: true;
$mq-static-breakpoint: 'large';
