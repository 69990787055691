.voo-menu {
}

.voo-menu__list {
  //sass-lint:disable-block no-important
  @extend %list-nostyle;
  @include mq($until: m) {
    margin-top: $spacing * 1.5 !important;
    margin-left: $spacing * 1.5 !important;
  }

  @include mq(m, l) {
    margin-top: $spacing * 1.5 !important;
    margin-left: $spacing * 0.75 !important;
  }
  @include mq('l') {
    display: flex;
    align-items: center;
  }
}

.voo-menu__list__item,
[class*='voo-menu__list__item--'] {
  @include mq($until: 'l') {
    font-size: 2.4rem;

    & + & {
      margin-top: $spacing / 2;
    }
  }

  @include mq('l') {
    & + & {
      margin-left: $spacing * 3.5;
    }
  }

  // Modifiers
  &[class*='--mobile'] {
    @include mq(l) {
      display: none;
    }
  }

  a {
    @extend %fw-bold;
    position: relative;
    color: $c-blue-dark;
    text-decoration: none;
    transition: color 0.1s $ease-out;

    &::after {
      content: '';
      position: absolute;
      bottom: -$spacing / 4;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $c-pink-medium;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform 0.3s $ease-out;
    }

    &:hover {
      color: $c-pink-medium !important; //sass-lint:disable-line no-important
    }

    .voo-header.is-light & {
      @include mq(l) {
        color: $white;
      }
    }

    [data-template='end-of-year'] &,
    [data-template='movers'] &,
    [data-template='gamers'] &,
    [data-template='homepage'] &,
    [data-template='modernization'] &,
    [data-template='mobile-network'] &,
    [data-template='net'] &,
    [data-template='mobile'] &,
    [data-template='fixe'] &,
    [data-template='net-homepass'] &,
    [data-template='tv'] &,
    [data-template='tv-extra'] &,
    [data-template='tv-vod'] &,
    [data-template='voo-tv-plus'] &,
    [data-template='voo-tv-plus-press'] & {
      @include mq(l) {
        color: $white !important; // sass-lint:disable-line no-important

        &:hover {
          color: $c-pink-medium !important; //sass-lint:disable-line no-important
        }
      }
    }

    // Will need review
    [data-template='a11y'] &,
    [data-template='assistant'] &,
    [data-template='assistant-result'] &,
    [data-template='blog'] &,
    [data-template='choose-voo'] &,
    [data-template='choose-voo-easy'] &,
    [data-template='choose-voo-my'] &,
    [data-template='eda'] &,
    [data-template='help'] &,
    [data-template='mobile-evolution'] &,
    [data-template='net-antivirus'] &,
    [data-template='net-cpl'] &,
    [data-template='net-wifree'] &,
    [data-template='net-modem'] &,
    [data-template='offers-packs'] &,
    [data-template='pack'] &,
    [data-template='pack-hero'] &,
    [data-template='rates'] &,
    [data-template='rates-fixe'] &,
    [data-template='rates-mobile'] &,
    [data-template='tv-evasion'] &,
    [data-template='tv-voomotion'] &,
    [data-template='smartphones-single'] &,
    [data-template='podcasts'] &,
    [data-template='page'] &,
    [data-template='youfirst'] &,
    [data-template='special-numbers'] &,
    [data-template='notfound'] &,
    [data-template='b2s'] &,
    [data-template='zuny'] &,
    [data-template='wysiwyg'] & {
      color: $c-blue-dark !important; // sass-lint:disable-line no-important

      &:hover {
        color: $c-pink-medium !important; //sass-lint:disable-line no-important
      }
    }

    &.is-active {
      color: $c-pink-medium !important; //sass-lint:disable-line no-important

      &::after {
        transform-origin: left;
        transform: scaleX(1);
      }
    }

    &.is-page {
      color: $c-blue-dark !important; //sass-lint:disable-line no-important

      &::after {
        content: none;
      }
    }
  }
}
