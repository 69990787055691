.voo-cart {
  position: relative;
  z-index: 9;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 45%;
    width: 1rem;
    height: 1rem;
    background: $white;
    transform: translateY(50%) rotate(45deg);
    visibility: hidden;
  }

  &.is-open {
    &::before {
      visibility: visible;
    }
  }
}

.voo-cart-inner {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  border: 0;
  padding: 0 $spacing;
  background: transparent;
}

.voo-cart__order {
  @extend %fw-bold;
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 20px;
  width: 1.8rem;
  height: 1.8rem;
  background-color: $c-pink-medium;
  color: $c-light;
  font-family: $ff-alt;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  border-radius: 50%;
  transform: translate(50%, 40%);
}

.voo-cart__icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: $c-light;
}

.voo-cart__panel {
  @extend %box-shadow;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
  width: 27rem;
  transform: translateX(-75%);
  border-radius: $border-radius;

  @include mq(l) {
    transform: translateX(-50%);
  }
}

.voo-cart__panel__close {
  position: absolute;
  top: $spacing / 1.4;
  right: $spacing;
  border: 0;
  background: transparent;
}


.voo-cart__panel__main {
  padding: $spacing;
  padding-top: $spacing / 2;
  background: $white;
  color: $c-blue-darker;
}


.voo-cart__panel__main__title {
  @extend %fw-bold;
  display: block;
  border-bottom: .1rem solid $c-blue-lightest;
  font-family: $ff-alt;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.voo-cart__panel__main__remove {
  @extend %button-nostyle;
  @extend %text-uppercase;
  @extend %fw-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: $spacing;
  border-bottom: .1rem solid $c-blue-lightest;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 1.4rem;

  svg {
    margin-right: $spacing / 2;
    fill: $c-pink-medium;
  }
}


.voo-cart__panel__main__message {
  margin: 0;
  margin-top: $spacing * 2;
  text-align: center;
}

.voo-cart__panel__main__offer {
  text-align: center;

  .product-card-selection__logo {
    margin: 0 0 $spacing * .5;
  }

  .product-card-selection__logo__img {
    width: 10.5rem;
  }

  .product-card-selection__products__figure {
    &:not(:last-child) {
      margin-right: $spacing * .75;
    }
  }

  .product-card-selection__products__figure__icon {
    width: auto;
    height: 3rem;
  }

  .product-card-selection__products__figure__title {
    display: none;
  }
}

.voo-cart__panel__cta {
  padding: $spacing 0;
  background: $c-blue-dark;
  text-align: center;

  .btn__icon {
    display: none;
  }
}
