.voo-services {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $spacing * 2;

  @include mq($until: 'm') {
    align-items: center;
    text-align: center;
  }

  @include mq('m', 'xl') {
    flex-direction: row;
  }

  @include mq('xl') {
    width: inner-col(12, 4);
    margin-bottom: 0;
    padding-left: 4rem;
  }

}

.voo-services__shop {
  width: inner-col(12, 10);

  @include mq('m', 'xl') {
    width: col(7);
    margin-right: col(1);
  }

  @include mq('xl') {
    width: inner-col(4, 4);
  }
}

.voo-services__shop__title {
  @extend %fw-bold;
  margin-top: 0;
  margin-bottom: $spacing;
  color: $c-light;
  line-height: 1;

  @include mq('m') {
    max-width: 16rem;
    margin-bottom: $spacing * 1.5;
  }
}

.voo-services__shop__picture-outer {
  position: relative;
  display: block;
  width: 100%;
  max-width: 24rem;
  height: 18rem;
  margin-top: -5rem;
  margin-right: auto;
  margin-left: auto;

  @include mq('m') {
    max-width: 36rem;
    margin-top: -7.5rem;
  }


  @include mq('l', 'xl') {
    max-width: 44rem;
    height: 22rem;
    margin-left: 0;
  }

  &:hover {
    [class*='services__shop__picture--'][class*='--pin'] {
      transform: translateY(-10%);
    }

    [class*='services__shop__picture--'][class*='--shadow'] {
      transform: translateY(30%);
    }
  }
}

.voo-services__shop__picture,
[class*='services__shop__picture--'] {
  @include image-fit(contain);
  z-index: 2;
  transition: transform .3s $ease-out-quad;
}

[class*='services__shop__picture--'][class*='--pin'] {
  z-index: 3;
  top: 5rem;
  right: 40%;
  left: 50%;
  width: 15%;
  height: auto;

  @include mq(m) {
    top: 2rem;
    right: 40%;
  }
}

[class*='services__shop__picture--'][class*='--shadow'] {
  z-index: 1;
  transform: translateY(20%);
}


.voo-services__app {
  @include mq($until: 'm') {
    width: col(10);
    margin-top: $spacing;
  }
}

.voo-services__app__title {
  @extend %fw-bold;
  margin-top: 0;
  margin-bottom: $spacing;
  color: $c-light;
  font-size: 1.8rem;
  line-height: 1;

  @include mq('l') {
    margin-top: 1.5em;
  }
}

.voo-services__app__list {
  display: flex;

  @include mq($until: 'm') {
    justify-content: center;
  }

  @include mq('m', 'xl') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.voo-services__app__item {
  position: relative;
  width: 100%;
  max-width: 20rem;
  height: 6rem;

  @include mq('m', 'xl') {
    width: 100%;
    max-width: 17rem;
    height: 5rem;
  }

  & +  & {
    margin-left: $spacing / 2;

    @include mq('m', 'xl') {
      margin-top: $spacing / 2;
      margin-left: 0;
    }
  }
}

.voo-services__app__item__link {
  transition: opacity .3s $ease-out-quad;

  &:hover {
    opacity: .5;
  }
}

.voo-services__app__item__picture {
  @include image-fit(contain);
  width: auto;
}
