.voo-secondary-menu {
  display: flex;
  width: 100%;

  @include mq($until: 'm') {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $spacing * 3;
    padding-top: $spacing * 1.5;
  }

  @include mq('m', 'l') {
    margin-bottom: $spacing * 5;
  }


  @include mq('l') {
    margin-bottom: $spacing * 6;
  }

  @include mq('xl') {
    width: col(8);
  }
}

.voo-secondary-menu__nav {
  margin-bottom: $spacing * 3;

  @include mq('m') {
    width: col(3);
    margin-bottom: 0;
  }

  @include mq('l') {
    width: col(2);
  }

  @include mq('xl') {
    width: inner-col(8, 2);
  }

  & + & {
    @include mq('m') {
      margin-left: col(1);
    }

    @include mq('l') {
      margin-left: col(2);
    }

    @include mq('xl') {
      margin-left: inner-col(8, 1);
    }

  }

  &:last-child {
    @include mq($until: 'm') {
      width: inner-col(12, 8);
      margin: 0 auto;
      text-align: center;
    }
  }
}

.voo-secondary-menu__nav__title {
  @extend %text-uppercase;

  margin-top: 0;
  margin-bottom: 1.5em;
  color: $gray-medium;
  font-size: 1.6rem;
  font-weight: 400;
}

.voo-secondary-menu__nav__list {
  @extend %list-nostyle;

  .voo-secondary-menu__nav:last-child & {
    @include mq($until: 'm') {
      display: flex;
      justify-content: center;
    }
  }
}

.voo-secondary-menu__nav__item {
  a {
    display: inline-flex;
    color: $c-light;
    font-size: 1.6rem;
    text-decoration: none;
    fill: $c-light;
    transition: opacity .2s $ease-out-quart;

    &:hover {
      opacity: .7;
    }
  }

  & + & {
    margin-top: .5rem;
  }

  .voo-secondary-menu__nav:last-child & {
    @include mq($until: 'm') {
      span {
        display: none;
      }

      svg {
        width: 4rem;
        height: 4rem;
      }

      & + .voo-secondary-menu__nav__item {
        margin-top: 0;
        margin-left: $spacing;
      }
    }
  }
}


.voo-secondary-menu__nav__item__icon {
  @include mq('l') {
    margin-right: $spacing / 2;
  }
}
