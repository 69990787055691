.voo-header-btn__link-outer {
  & + & {
    border-top: 1px solid $c-blue-medium;
  }
}

.voo-header-btn__link {
  display: flex;
  align-items: center;
  padding: $spacing $spacing * 2;
  background-color: $c-blue-dark;
  color: $white;
  text-decoration: none;

  @include mq(m) {
    padding-left: $spacing * 4;
  }
}

.voo-header-btn__link__icon {
  margin-right: $spacing / 2;
  fill: $c-pink-medium;
}
