.voo-logo {
  display: inline-block;
  will-change: transform;
  fill: $c-pink-medium;
  transition: fill 0.2s ease-out;

  [data-template='a11y'] &,
  [data-template='assistant'] &,
  [data-template='assistant-result'] &,
  [data-template='blog'] &,
  [data-template='choose-voo'] &,
  [data-template='choose-voo-easy'] &,
  [data-template='choose-voo-my'] &,
  [data-template='eda'] &,
  [data-template='help'] &,
  [data-template='mobile-evolution'] &,
  [data-template='net-antivirus'] &,
  [data-template='net-cpl'] &,
  [data-template='net-wifree'] &,
  [data-template='net-modem'] &,
  [data-template='offers-packs'] &,
  [data-template='pack'] &,
  [data-template='pack-hero'] &,
  [data-template='rates'] &,
  [data-template='rates-fixe'] &,
  [data-template='rates-mobile'] &,
  [data-template='tv-evasion'] &,
  [data-template='tv-voomotion'] &,
  [data-template='s2n-store'] &,
  [data-template='smartphones-single'] &,
  [data-template='podcasts'] &,
  [data-template='page'] &,
  [data-template='youfirst'] &,
  [data-template='special-numbers'] &,
  [data-template='notfound'] &,
  [data-template='s2n-radio'] &,
  [data-template='s2n-tv'] &,
  [data-template='s2n-call'] &,
  [data-template='s2n-store'] &,
  [data-template='s2n-thank-you'] &,
  [data-template='s2n-installation'] &,
  [data-template='b2s'] &,
  [data-template='zuny'] &,
  [data-template='wysiwyg'] & {
    fill: $c-pink-medium !important; // sass-lint:disable-line no-important
  }

  [data-template='homepage'] &,
  [data-template='end-of-year'] &,
  [data-template='movers'] &,
  [data-template='gamers'] &,
  [data-template='modernization'] &,
  [data-template='mobile-network'] &,
  [data-template='mobile'] &,
  [data-template='fixe'] &,
  [data-template='net'] &,
  [data-template='tv'] &,
  [data-template='net-homepass'] &,
  [data-template='tv-vod'] &,
  [data-template='tv-extra'] &,
  [data-template='voo-tv-plus'] &,
  [data-template='voo-tv-plus-press'] &,
  .voo-header.is-light & {
    @include mq(l) {
      fill: $white;
    }
  }
}

.voo-logo.s2n {
  display: flex;
  align-items: center;

  .separator {
    height: 25px;
    width: 1px;
    background: $c-blue-dark;
    opacity: 0.55;
    margin: 0 20px 0 17px;

    @include mq(xl) {
      height: 35px;
      margin: 0 30px 0 27px;
    }
  }

  .signal {
    width: 88px;

    @include mq(xl) {
      width: 125px;
    }
  }

  [data-template='s2n-homepage'] & {
    @include mq(l) {
      .separator {
        background: $white;
      }

      .signal * {
        fill: $white;
      }
    }
  }
}
